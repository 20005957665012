class LocalStorageManager {
    static isBrowser() {
        return typeof window !== "undefined";
    }

    static setItem(key: string, value: string) {
        if (this.isBrowser()) {
            localStorage.setItem(key, value);
        }
    }

    static getItem(key: string) {
        if (this.isBrowser()) {
            return localStorage.getItem(key);
        }
        return null;
    }

    static removeItem(key: string) {
        if (this.isBrowser()) {
            localStorage.removeItem(key);
        }
    }
}

export default LocalStorageManager;