exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-blogs-tsx": () => import("./../../../src/templates/blogs.tsx" /* webpackChunkName: "component---src-templates-blogs-tsx" */),
  "component---src-templates-book-demo-tsx": () => import("./../../../src/templates/book-demo.tsx" /* webpackChunkName: "component---src-templates-book-demo-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-pricing-tsx": () => import("./../../../src/templates/pricing.tsx" /* webpackChunkName: "component---src-templates-pricing-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-products-tsx": () => import("./../../../src/templates/products.tsx" /* webpackChunkName: "component---src-templates-products-tsx" */),
  "component---src-templates-solution-tsx": () => import("./../../../src/templates/solution.tsx" /* webpackChunkName: "component---src-templates-solution-tsx" */),
  "component---src-templates-solutions-tsx": () => import("./../../../src/templates/solutions.tsx" /* webpackChunkName: "component---src-templates-solutions-tsx" */)
}

