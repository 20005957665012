import "./src/styles/global.css";
// gatsby-browser.js
import { navigate } from "gatsby";
import LocalStorageManager from "./src/utils/localstorage-maanger";

export const onInitialClientRender = () => {
  const defaultLocale = "en";
  const supportedLocales = ["en", "ar"];
  const browserLocale = navigator.language.split("-")[0]; // Get the first part of the browser locale

  
  let locale = LocalStorageManager.getItem("locale");

  // If no locale is stored, determine it from the browser or fallback to default
  if (!locale) {
    locale = supportedLocales.includes(browserLocale) ? browserLocale : defaultLocale;
    LocalStorageManager.setItem("locale", locale);
  }

  // Redirect to the localized version if needed
  if (typeof window !== "undefined") {
    const currentPath = window.location.pathname;

    const localeInPath = supportedLocales.some((supportedLocale) =>
      currentPath.startsWith(`/${supportedLocale}`)
    );

    // If the current path doesn't have a locale prefix and isn't the homepage
    if (!localeInPath) {
      const newPath = currentPath === '/' || currentPath === '' ? `/${locale}/` : `/${locale}${currentPath}`;
      navigate(newPath.replace(/\/\/+/g, '/')); // Navigate and avoid double slashes
    }
  }
};
